import React from "react";
import { Container, Flex, Box, css } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import Reveal from "@solid-ui-components/Reveal";
import ContentImages from "@solid-ui-components/ContentImages";
import QuickSignupForm from "@solid-ui-components/QuickSignupForm";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";

const styles = {
  title: {
    background: (t) => `${t.colors.alphaDark}`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontSize: 6,
    fontWeight: "normal",
  },
};

const HeroBlock01 = ({
  content: { kicker, title, text_1, images, buttons, form },
  reverse,
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: [null, null, null, `center`],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          null,
          null,
          reverse ? `row-reverse` : `row`,
        ],
      }}
    >
      <Box
        sx={{
          flexBasis: [null, null, null, `3/5`],
          [reverse ? "ml" : "mr"]: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`,
        }}
      >
        <ContentImages
          content={{ images }}
          loading="eager"
          reverse={reverse}
          imagePosition="center"
        />
      </Box>
      <Box
        sx={{
          flexBasis: `2/5`,
          textAlign: [`center`, null, null, `left`],
        }}
      >
        <Reveal effect="fadeInDown">
          <ContentText content={kicker} variant="h4" />
          <ContentText
            content={title}
            variant="h2"
            mb="4"
            sx={{
              ...(!title?.color && styles.title),
            }}
          />
          <ContentText content={text_1} variant="medium" mb="0" />
        </Reveal>
        {buttons && (
          <Reveal
            effect="fadeInRight"
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            <ContentButtons content={buttons} space={3} />
          </Reveal>
        )}
        {form && (
          <Reveal
            effect="fadeInRight"
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            <QuickSignupForm {...form} space={3} />
          </Reveal>
        )}
      </Box>
    </Flex>
  </Container>
);

export default WithDefaultContent(HeroBlock01);
