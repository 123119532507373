import React from "react";
import { Container } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import ContentContainer from "@solid-ui-components/ContentContainer";
import StackTitle from "@solid-ui-components/StackTitle";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";

const CallToActionBlock01 = ({
  content: { container, kicker, title, text_1, text_2, buttons },
}) => (
  <Container sx={{ textAlign: `center` }}>
    <StackTitle kicker={kicker} title={title} />
    <ContentContainer content={container} variant="cards.paper-lg">
      <ContentText content={text_1} variant="h4" sx={{ fontWeight: `body` }} />
      <ContentText content={text_2} variant="h4" sx={{ fontWeight: `body` }} />
      <ContentButtons content={buttons} space={3} />
    </ContentContainer>
  </Container>
);

export default WithDefaultContent(CallToActionBlock01);
