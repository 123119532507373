import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import StackTitle from '@solid-ui-components/StackTitle'
import Counter from '@solid-ui-components/Counter'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const StatsBlock01 = ({
  content: { kicker, title, text_1, text_2, container, collection, buttons }
}) => (
  <Container sx={{ textAlign: `center` }}>
    <ContentContainer content={container} variant='cards.paper-lg'>
      <StackTitle kicker={kicker} title={title} lines={[text_1, text_2]} />
      {collection && (
        <>
          <Flex
            sx={{
              flexWrap: `wrap`,
              alignItems: `flex-start`,
              alignContent: `center`,
              m: -1
            }}
          >
            {collection?.map(({ title, description }, index) => (
              <Box
                key={`item-${index}`}
                sx={{
                  flexBasis: [`1/2`, null, null, `1/4`],
                  textAlign: `center`,
                  p: 1
                }}
              >
                <Reveal effect='fadeInGrow' delay={0.2 * (index + 2)}>
                  <ContentText content={title} variant='h1' mb='0'>
                    <Counter to={parseInt(title?.text)} />
                  </ContentText>
                  <ContentText
                    content={description}
                    variant='h5'
                    sx={{ fontWeight: `body` }}
                    mb='0'
                  />
                </Reveal>
              </Box>
            ))}
          </Flex>
        </>
      )}
      {collection && buttons && <Divider space={4} />}
      <ContentButtons content={buttons} space={0} />
    </ContentContainer>
  </Container>
)

export default WithDefaultContent(StatsBlock01)
