import React from "react";
import { Container, Flex, Box, css } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import Icon from "@solid-ui-components/ContentIcon";
import ContentContainer from "@solid-ui-components/ContentContainer";
import Reveal from "@solid-ui-components/Reveal";
import StackTitle from "@solid-ui-components/StackTitle";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";

const styles = {
  listItem: {
    flexBasis: [`1`, null, `1/2`],
    alignItems: `center`,
    justifyContent: `center`,
    // p: [3, 4],
    p: [3, 3],
    ":nth-of-type(even)": {
      borderLeftStyle: `solid`,
      borderLeftColor: `omegaLight`,
      borderLeftWidth: [0, null, null, `sm`],
    },
    ":nth-last-of-type(2), :last-child": {
      borderTopStyle: `solid`,
      borderTopColor: `omegaLight`,
      borderTopWidth: [0, null, null, `sm`],
    },
  },
};

const FeaturesBlock04 = ({
  content: { container, kicker, title, text_1, text_2, collection },
}) => (
  <Container>
    <StackTitle kicker={kicker} title={title} lines={[text_1, text_2]} />
    <ContentContainer content={container} as={Reveal} variant="cards.paper">
      <Flex sx={{ flexWrap: `wrap` }}>
        {collection?.map(({ title, description, icon, buttons }, index) => (
          <Box key={`item-${index}`} css={css(styles.listItem)}>
            <Flex sx={{ alignItems: `center`, mb: 3 }}>
              <Icon content={icon} size="sm" mr="3" />
              <ContentText content={title} variant="h4" mb="0" />
            </Flex>
            <Box ml={2}>
              <ContentText content={description} />
              <ContentButtons content={buttons} space={2} />
            </Box>
          </Box>
        ))}
      </Flex>
    </ContentContainer>
  </Container>
);

export default WithDefaultContent(FeaturesBlock04);
