import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ContentContainer from '@solid-ui-components/ContentContainer'
import StackTitle from '@solid-ui-components/StackTitle'
import ListItem from '@solid-ui-components/ListItem'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import getImage from '@solid-ui-components/utils/getImage'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const FeaturesProducts = ({
  content: { kicker, title, text_1, text_2, collection, images, buttons }, content
}) => (
  <Container sx={{ textAlign: `center` }}>  
    <StackTitle kicker={kicker} title={title} lines={[text_1, text_2]} />
    {collection && (
      <Reveal effect='fadeInDown' duration={1}>
        <Flex sx={{ justifyContent: `center`, flexWrap: `wrap`, m: -3 }}>
          {collection.map(({ container, images, ...props }, index) => (
            <Box
              key={`item-${index}`}
              sx={{ flexBasis: [`1`, `1/2`, null, `1/4`], p: 3 }}
            >
              <ContentContainer content={container}>
                <Box sx={{width:'150px', margin:'0 auto'}}>
                 
                  {images && (<Img
                      image={getImage(images[0].src)}
                      alt={images[0].alt}
                      objectFit='contain'
                  />)}
                </Box>
                <ListItem
                  {...props}
                  iconProps={{ round: true }}
                  vertical
                  center
                />
              </ContentContainer>
            </Box>
          ))}
        </Flex>
      </Reveal>
    )}
    <ContentButtons content={buttons} />
  </Container>
)

export default WithDefaultContent(FeaturesProducts)
