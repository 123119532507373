import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01";
// import ModalSimple from "@solid-ui-blocks/Modal/Block02";
import Header from "@solid-ui-blocks/Header/Block01";
import Hero from "@solid-ui-blocks/Hero/Block01";
// import Companies from "@solid-ui-blocks/Companies/Block01";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block01";
import WhyChooseUs from "@solid-ui-blocks/Features/Block04";
import Stats from "@solid-ui-blocks/Stats/Block01";
import Products from "@solid-ui-blocks/Features/Products";
// import Testimonials from "@solid-ui-blocks/Testimonials/Block02";
import GetStarted from "@solid-ui-blocks/CallToAction/Block01";
import Footer from "@solid-ui-blocks/Footer/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import theme from "./_theme";
import { styles } from "./styles/_index.styles";

const IndexPage = (props) => {
  let preSelectedLanguage = 'en';
  if (typeof window !== 'undefined') {
    preSelectedLanguage = localStorage.getItem("selectedLanguage");
  } 
  
  const { allBlockContent, site } = useStaticQuery(query);
  const [selectedlanguage, setSelectedlanguage] = useState(
    preSelectedLanguage
      ? preSelectedLanguage
      : site.siteMetadata.languages.defaultLangKey
  );

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  function switchLanguage(lang) {
    setSelectedlanguage(lang);
  }

  if (typeof window !== 'undefined') {
    localStorage.setItem("selectedLanguage", selectedlanguage);
  }


  return (
    <Layout theme={theme} {...props}>
      <Seo title="Home" />
      {/* Amera Header: TODO Moving into Layout */}

      <Header
        switchLanguage={switchLanguage}
        languages={site.siteMetadata.languages.langs}
        content={content[`header.${selectedlanguage}`]}
      />
      <Divider space="5" />
      {/* Modals */}
      <ModalWithTabs
        content={content[`authentication.${selectedlanguage}`]}
        reverse
      />
      <ModalWithTabs content={content[`contact.${selectedlanguage}`]} />

      {/* Amera Hero */}
      <Container variant="full" sx={styles.heroContainer}>
        <Hero content={content[`hero.${selectedlanguage}`]} reverse />
      </Container>
      <Divider space="4" />
      <Divider space="5" />

      {/* Amera Products */}
      <Container variant="full" sx={styles.buildBrandContainer}>
        <Products content={content[`products.${selectedlanguage}`]} />
      </Container>
      <Divider space="5" />

      {/* Amera Value Propositions */}
      <WhyChooseUs content={content[`why-choose-us.${selectedlanguage}`]} />
      <Divider space="5" />

      {/* Amera Featured Product: AmeraKey */}
      <FeatureOne
        content={content[`featured-product.${selectedlanguage}`]}
        reverse
      />
      <Divider space="5" />

      {/* Amera Stats */}
      <Stats content={content[`stats.${selectedlanguage}`]} />
      <Divider space="5" />

      {/* Amera Early Access */}
      <Container sx={styles.getStartedContainer}>
        <GetStarted content={content[`book-demo.${selectedlanguage}`]} />
      </Container>
      <Divider space="5" />

      {/* Amera Footer: TODO Moving into Layout */}
      <Footer content={content[`footer.${selectedlanguage}`]} />
    </Layout>
  );
};

const query = graphql`
  query siteIndexBlockContent {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/index/hero"
            "site/index/products"
            "site/index/why-choose-us"
            "site/index/featured-product"
            "site/index/stats"
            "site/shared/header"
            "site/shared/footer"
            "site/shared/contact"
            "site/shared/authentication"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
export default IndexPage;
